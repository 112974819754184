import {useEffect, useState} from "react";
import axios from "axios";
import Loading from "../../../examples/loading";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import {useNavigate} from "react-router-dom";
import url from "../../../config/index"

// const user = JSON.parse(localStorage.getItem("user"))
const config = {
    headers: {
        // Authorization: `Bearer ${user.token}`,
    }
};

const Logout = () => {
    const navigate = useNavigate();
    useEffect(() => {
        axios.get(`${url}auth/logout`, config).then((response) => {
            localStorage.removeItem("user");
            localStorage.setItem("user", '')
            localStorage.setItem("email", "")
            localStorage.setItem("name", "")
            localStorage.setItem("showRateProvider", false)
            navigate("/")
        })
    }, [])

    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <Loading open/>
        </DashboardLayout>
    );
}

export default Logout
