import React from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from "@mui/material/FormControl";
import InputController from '../../../components/CustomInput/component/InputController'
import {Controller, useForm} from "react-hook-form";
import Box from "@mui/material/Box";
import { Typography, Grid } from '@mui/material';
import { AutocompleteComponent } from '../../../components/CustomInput/component/autocompleteComponent';

const days = ['Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado', 'Domingo']

export default function DialogPostulateCandidateComponent({handleClose, open, onPostulate}) {
    const {control, handleSubmit, formState: {errors}} = useForm();
    const vacancy = JSON.parse(localStorage.getItem('vacancy'))
    const candidate = JSON.parse(localStorage.getItem('candidateVacancy'))
    return (
            <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
                <form onSubmit={handleSubmit(onPostulate)}>
                    <DialogTitle>Postular Candidato</DialogTitle>
                    <DialogContent>
                    <Typography variant="subtitle1" component="div">
                    {
                        `¿Desea postular al candidato ${candidate?.name} ${candidate?.lastname} A la vacante ${vacancy?.name}?`
                    }
                    </Typography>
                    <Box style={{marginTop: "5%"}}/>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant="h6" component="div">
                                Disponibilidad entrevista
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <Controller
                                    name="interviewDay"
                                    control={control}
                                    rules={{required: true}}
                                    render={({ field: { onChange, value } })  =>
                                        (<AutocompleteComponent
                                            onChange={onChange}
                                            listValue={days}
                                            valueSelected={value || []}
                                            label='Dia de entrevista'
                                            placeholder='Seleccione el dia'
                                            variant='standard'
                                        />)
                                        }
                                        />
                                        {errors.interviewDay && errors.interviewDay.type === "required" &&
                                        <span style={{fontSize: 12, color: "red"}}>Es requerido</span>}
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}> 
                            <FormControl fullWidth>
                                <InputController 
                                    rules={{required: true}}
                                    name="interviewHour" 
                                    control={control}
                                    type="text"
                                    defaultValue="" 
                                    label="Disponibilidad hora"
                                    variant='standard'/>
                                {errors.interviewHour && errors.interviewHour.type === "required" &&
                                        <span style={{fontSize: 12, color: "red"}}>Es requerido</span>}
                            </FormControl>
                        </Grid>
                    </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancelar</Button>
                        <Button type='submit'>Postular</Button>
                    </DialogActions>
                </form>
            </Dialog>
    );
}
