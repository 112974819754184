import React, { useState } from "react";

import { useForm } from "react-hook-form";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, FormControl } from "@mui/material";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import { CustomAxios } from "../../../components/custom/axiosCustom";
import { Config } from "../../../utils/configHeader";
import { ErrorAlert, SuccessAlert } from "../../../components/custom/SwalCustom";
import { InputNumberFormatController, InputController } from "../../../components/CustomInput";
import UploadFile from "../../../examples/UploadFile";

const validationFileInitial = {
    required: false,
    type: false,
    size: false
}

const schema = yup.object().shape({
    ccEmail: yup.string().email("Debe ser un correo válido"),
    total: yup.string().required("Es Requerido"),
}).required();

export const ResendInvoiceManualComponent = ({handleClose, open, notLoading, loading, title, invoiceToResend, reloadInvoice}) => {
    console.log(handleClose, open, notLoading, loading, title);
    const { control, handleSubmit, reset, watch, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
    });
    const [file, setFile] = useState()
    const [validateFile, setValidateFile] = useState(validationFileInitial)

    const resetField = () => {
        reset({
            ccEmail: "",
            total: "",
            comments: "",
            itbmsTax: 0,
            subtotal: 0,
        })
        setFile()
    }

    const validateSizeFile = (files) => {
        if (files.size < 3000000) {
            setValidateFile({
                required: false,
                type: false,
                size: false
            })

            return false
        }
        if (files.size > 3000000) {
            setValidateFile({
                required: false,
                type: false,
                size: true
            })

            return true
        }
    }

    const validateTypeFile = (files) => {
        if (files.type === "application/pdf" || files.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
            setValidateFile({
                required: false,
                type: false,
                size: false
            })

            return true
        }
        setValidateFile({
            required: false,
            type: true,
            size: false
        })
        return false
    }

    const requiredFile = () => {
        setValidateFile({
            type: false,
            size: false,
            required: true,
        })
    }

    const handleChangeFile = e => {
        if (e.target.files.length) {
            let isFileType = validateTypeFile(e.target.files[0])
            if (isFileType) {
                let isFileSize = validateSizeFile(e.target.files[0])
                if (!isFileSize) {
                    setFile(e.target.files[0])
                }
            }
            return null
        }
        requiredFile()
    };

    const onClose = () => {
        setFile()
        setValidateFile(validationFileInitial)
        resetField()
        handleClose()
    }

    const onRegister = (value) => {
        if (file === undefined) {
            requiredFile()
            return null;
        }

        if (!validateTypeFile(file) && validateSizeFile(file)) {
            return null;
        }

        loading()
        onClose()
        let formData = new FormData();
        formData.append("file", file);
        formData.append("invoiceId", invoiceToResend)
        formData.append("ccEmail", value["ccEmail"]);
        formData.append("email", localStorage.getItem('email'));
        formData.append("comments", value["comments"]);
        formData.append("itbmsTax", value["itbmsTax"]);
        formData.append("subtotal", value["subtotal"]);
        formData.append("total", value["total"]);

        CustomAxios.post(`invoice-submissions/resend-manual-invoice`, formData, Config()).then(() => {
            notLoading()
            SuccessAlert("Cargar de Factura", "Realizada con Exito").then(() => reloadInvoice())
        }).catch(({ response: { data } }) => {
            notLoading()
            ErrorAlert("Error al Cargar Factura", data.message).then()
        })
    }

    return (
        <>
            <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
                <form onSubmit={handleSubmit(onRegister)}>
                    <DialogTitle>{title}</DialogTitle>
                    <DialogContent>
                        <Box p={1}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <InputNumberFormatController name="subtotal" control={control}
                                            type="string"
                                            defaultValue="" label="Subtotal" />
                                        {errors.subtotal &&
                                            <span style={{ fontSize: 12, color: "red" }}>{errors.subtotal.message}</span>}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <InputNumberFormatController name="itbmsTax" control={control}
                                            defaultValue="" label="ITBMS" />
                                        {errors.itbmsTax &&
                                            <span style={{ fontSize: 12, color: "red" }}>{errors.itbmsTax.message}</span>}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <InputNumberFormatController name="total" control={control}
                                            defaultValue="" label="Total" />
                                        {errors.total &&
                                            <span style={{ fontSize: 12, color: "red" }}>{errors.total.message}</span>}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <InputController name="ccEmail" control={control}
                                            type="email"
                                            defaultValue="" label="Correo de Copia" />
                                        {errors.ccEmail &&
                                            <span style={{ fontSize: 12, color: "red" }}>{errors.ccEmail.message}</span>}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <InputController name="comments" control={control}
                                            type="string"
                                            defaultValue="" label="Comentario" />
                                        {errors.comments &&
                                            <span
                                                style={{ fontSize: 12, color: "red" }}>{errors.comments.message}</span>}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box mb={2}>
                                        <UploadFile
                                            id="coverPage"
                                            nameInput="cover"
                                            onChangeFile={handleChangeFile}
                                            file={file}
                                            textButton="Cargar Factura"
                                            fileLoaded="Factura Cargada"
                                        />
                                        {validateFile.size &&
                                            <span style={{ fontSize: 12, color: "red" }}>El archivo no debe exceder los 3 MB</span>}
                                        {validateFile.type &&
                                            <span style={{ fontSize: 12, color: "red" }}>Solo debe ser formato (.pdf o .docx)</span>}
                                        {validateFile.required &&
                                            <span style={{ fontSize: 12, color: "red" }}>El campo es requerido</span>}
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onClose}>Cancelar</Button>
                        <Button type="submit">Reenviar</Button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    )
}