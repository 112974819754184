import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

export const AutocompleteComponent = ({ listValue, valueSelected, onChange, label, placeholder, variant = 'standard' }) => {
    const options = listValue.map(value => ({ label: value, value }));
    return (
        <Autocomplete
            multiple
            fullWidth
            value={valueSelected}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            id={`tags-${variant}}`}
            onChange={(_event, newValue) => {
                onChange(newValue);
            }}
            options={options.sort((a, b) => -b.label[0].localeCompare(a.label[0]))}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant={variant}
                    label={label}
                    placeholder={placeholder}
                />
            )}
        />
    );
};
