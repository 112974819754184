import React, {useState} from 'react';

import format from "date-fns/format";
import {Box, Button, Card, CardContent, CardHeader, Typography} from "@mui/material";
import {Link, useNavigate} from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";

import {dollarUS} from "../../../utils/moneyFormat";
import DialogSupportComponent from "../../../examples/Navbars/DashboardNavbar/component/dialogSupportComponent";
import {
    contentSupportVacancy,
    titleSupportVacancy
} from "../../../examples/Navbars/DashboardNavbar/component/messageDialog";
import url from "../../../config";
import {Config} from "../../../utils/configHeader";

export default function CardJobsComponent({vacancy}) {
    const navigate = useNavigate()
    const [openDialog, setOpenDialog] = useState(false)
    const [message, setMessage] = useState('')

    const onClickOpenDialog = () => setOpenDialog(true);
    const onClickCloseDialog = () => setOpenDialog(false);
    const onChangeMessage = (e) => setMessage(e.target.value)

    const onSendMessage = () => {
        onClickCloseDialog()
        axios.post(`${url}vacancies/${vacancy.id}/send-message/`, {message}, Config()).then((response) => {
            Swal.fire(
                "Mensaje Enviado",
                "Con Exito",
                'success'
            ).then()
        }).catch((error) => {})
    }

    const getPriority = (priority) => {
        switch (priority) {
            case "1 (Alta)":
                return "Alta"
            case "2 (Media)":
                return "Media"
            case"3 (Baja)":
                return "Baja"
        }
    }

    const onRegisterCandidate = () => {
        localStorage.setItem("vacancy", JSON.stringify(vacancy))
        navigate('/gestion-de-candidatos/candidatos/registro')
    }

    var showRateProvider = (localStorage.getItem("showRateProvider") == 'true') ? true : false;

    return (
        <Card style={{marginBottom: 20}}>
            <CardHeader title={vacancy.name}/>
            <CardContent>
                <Typography paragraph style={{fontSize: "15px"}}>
                    {vacancy.description}
                </Typography>
                <Typography paragraph style={{fontSize: "15px"}}>
                    {showRateProvider ? <b>Rate máximo: {dollarUS. format (vacancy. rateMaxUSD)} </b> : null}
                </Typography>
                <Typography paragraph style={{fontSize: "15px"}}>
                    {showRateProvider ? <b>Rate mínimo: {dollarUS. format (vacancy. rateMinUSD)} </b> : null}
                </Typography>
                <Typography paragraph style={{fontSize: "15px"}} display="flex">
                    <b>Fecha de apertura: </b><p style={{color: "white"}}>P</p><p
                    style={{color: 'red'}}>{" " + format(new Date(vacancy.createdAt), "dd-MM-yyyy")}</p>
                </Typography>
                <Typography paragraph style={{fontSize: "15px"}}>
                    <b>Prioridad: </b>{getPriority(vacancy.priority)}
                </Typography>
                <Typography paragraph style={{fontSize: "15px"}}>
                    <b>Equipo de trabajo requerido: </b>{vacancy.requiredEquipment}
                </Typography>
                <Box display='flex' justifyContent='end'>
                    <Button variant="contained" style={{backgroundColor: "black", color: "white", marginRight: 10}}
                            onClick={onClickOpenDialog}>Consulta sobre Vacante</Button>
                    <Link to={"/vacantes/" + vacancy.id}>
                        <Button variant="contained" style={{backgroundColor: "black", color: "white", marginRight: 10}}>Más
                            Detalles</Button>
                    </Link>
                    <Button variant="contained" style={{backgroundColor: "black", color: "white"}}
                            onClick={onRegisterCandidate}>Postular Candidato</Button>
                </Box>
                <DialogSupportComponent
                    open={openDialog}
                    title={titleSupportVacancy}
                    content={contentSupportVacancy}
                    onClickClose={onClickCloseDialog}
                    message={message}
                    onChangeMessage={onChangeMessage}
                    onSend={onSendMessage}
                />
            </CardContent>
        </Card>
    );
}
