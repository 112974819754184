import { useState } from "react";

import { Grid } from "@mui/material";
import {  Upload, Visibility } from "@mui/icons-material";
import { Link } from "react-router-dom";
import MDBox from "../../../components/MDBox/index.js";

import MenuCard from "../../../examples/Cards/MenuCards"


import { DialogChargedInvoiceComponent } from "./dialogChargedInvoiceComponent";

export const ItemInvoiceStandartComponent = ({ thisLoading, notLoading}) => {
  const [dialog, setDialog] = useState(false);

  const onOpenDialog = () => setDialog(true);
  const onCloseDialog = () => setDialog(false);


  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6} lg={4} xl={4}>
        <MDBox mb={1.5}>
          <Link to="/gestion-de-facturas/facturas-enviadas">
            <MenuCard icon={Visibility} title="Facturas Enviadas" />
          </Link>
        </MDBox>
      </Grid>
 
        <Grid item xs={12} md={6} lg={4} xl={4} onClick={onOpenDialog}>
          <MDBox mb={1.5}>
            <MenuCard icon={Upload} title="Cargar Factura" />
          </MDBox>
        </Grid>


      <DialogChargedInvoiceComponent
        notLoading={notLoading}
        loading={thisLoading}
        handleClose={onCloseDialog}
        open={dialog}
      />

    </Grid>
  );
};
