import React from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from "@mui/material/FormControl";
import {Controller, useForm} from "react-hook-form";
import Box from "@mui/material/Box";
import { Grid, TextField, Typography } from '@mui/material';
import {Candidate} from "../../../utils/candidate";
import AutocompleteComponent from "./autocompleteComponent";
import { AutocompleteComponent as AutocompleteComponentDay } from '../../../components/CustomInput/component/autocompleteComponent';

const days = ['Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado', 'Domingo']

export default function DialogCandidateComponent({handleClose, open, onChangeCandidate,onChangeDay, onChangeHour, candidateSelected, onRegister}) {
    const {control, handleSubmit, formState: {errors}} = useForm();
    const candidates = Candidate()

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
            <form onSubmit={handleSubmit(onRegister)}>
            <DialogTitle>Postular Candidatos</DialogTitle>
            <DialogContent>
                <Box style={{marginTop: "5%"}}/>
                <FormControl fullWidth>
                    <Controller
                        name="candidate"
                        control={control}
                        rules={{required: true}}
                        render={({ field: { onChange } }) =>
                            (<AutocompleteComponent
                                onChange={(newValue) => {
                                    onChange(newValue)
                                    onChangeCandidate(newValue)
                                }}
                                candidates={candidates}
                                candidate={candidateSelected}
                            />)
                        }
                    />
                    {errors.candidate && errors.candidate.type === "required" &&
                    <span style={{fontSize: 12, color: "red"}}>Es requerido</span>}
                </FormControl>
                <Grid container spacing={3}>
                {
                    candidateSelected?.map(candidate => (
                    <>
                        <Grid item xs={12} marginTop='20px'>
                            <Typography variant="h6" component="div">
                                {
                                    `Disponibilidad entrevista de ${candidate?.name} ${candidate?.lastname}`
                                }
                        `   </Typography>
                        </Grid>
                        <Grid key={candidate.id} item xs={6} >
                        <FormControl fullWidth>
                        <Controller
                            name={`interviewDay_${candidate.name}}`}
                            control={control}
                            rules={{required: true}}
                            render={({ field: { onChange, value } })  =>
                                (<AutocompleteComponentDay
                                    onChange={(newValue) => {
                                        onChange(newValue)
                                        onChangeDay(candidate.id, newValue)
                                    }}
                                    listValue={days}
                                    valueSelected={value || []}
                                    label='Dia de entrevista'
                                    placeholder='Seleccione el dia'
                                    variant='standard'
                                />)
                                }
                                />
                                {errors[`interviewDay_${candidate.name}}`] && errors[`interviewDay_${candidate.name}}`].type === "required" &&
                                <span style={{fontSize: 12, color: "red"}}>Es requerido</span>}
                            </FormControl>
                        </Grid>
                        <Grid key={candidate.name} item xs={6}>
                        <FormControl fullWidth>
                        <Controller
                            name={`interviewHour_${candidate.name}}`}
                            control={control}
                            rules={{ required: true }}
                            render={({ field }) => (
                            <FormControl fullWidth>
                                <TextField
                                {...field}
                                label="Disponibilidad hora"
                                fullWidth
                                type="text"
                                id="input-text-standard"
                                variant="standard"
                                value={field.value || ''}
                                onChange={(e) => {
                                    field.onChange(e);
                                    onChangeHour(candidate.id, e.target.value);
                                }}
                                />
                            </FormControl>
                            )}
                        />
                            {errors[`interviewHour_${candidate.name}}`] && errors[`interviewHour_${candidate.name}}`].type === "required" &&
                                <span style={{fontSize: 12, color: "red"}}>Es requerido</span>}
                        </FormControl>
                        </Grid>                        
                    </>
                    ))
                }
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancelar</Button>
                <Button type='submit'>Postular</Button>
            </DialogActions>
            </form>
        </Dialog>
    );
}
