import * as React from 'react';
import MaterialTable, {Column} from "@material-table/core";

import {localization, tableIcons} from "../../config/configMaterialTableGeneral";

const TableStandertComponent = ({title, options, columns, data, action}) => {
    return (
        <MaterialTable
            title={title}
            icons={tableIcons}
            columns={columns}
            localization={localization}
            options={options}
            data={data}
            actions={action}
        />
    )
}

export default TableStandertComponent
