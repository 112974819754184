import React, {useEffect, useState} from "react";

import axios from "axios";
import Grid from "@mui/material/Grid";
import {useParams} from "react-router-dom";
import Box from "@mui/material/Box";
import Swal from "sweetalert2";
import {LoadingButton} from "@mui/lab";

import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import Loading from "../../examples/loading";
import MDBox from "../../components/MDBox";
import CardJobsComponent from "./component/cardJobsComponent";
import url from "../../config";
import {Config, ConfigHeaderBlobResponse} from "../../utils/configHeader";
import PaginationComponent from "../../examples/Pagination";
import {SearchInputComponent} from "./component/searchInputComponent";
import {useForm} from "react-hook-form";
import DialogPostulateCandidateComponent from "./component/dialogPostulateCandidateComponent";

const perChunk = 5

function Dashboard() {
    const [open, setOpen] = useState(false)
    const [openDialog, setOpenDialog] = useState(false)
    const [vancancies, setVacancies] = useState([])
    const [allVacancies, setAllVacancies] = useState([])
    const [allVacancy, setallVacancy] = useState([])
    const [controlledModalVacancy, setControlledModalVacancy] = useState(false)
    const [page, setPage] = useState(1);
    const [countPage, setCountPage] = useState(1)
    const [isButtonLoading, setIsButtonLoading] = useState(false)
    const {control, watch, reset, formState: {errors}} = useForm({})
    useParams();

    const search = watch("search")

    const handleClose = () => {
        localStorage.removeItem('vacancy')
        localStorage.removeItem('candidateVacancy')
        setOpenDialog(false);
        setOpen(false)
    };

    const handleSuccess = (response) => {
        setOpen(false);
        Swal.fire("Postulación", "Registrada con éxito", 'success').then(() => {
            localStorage.removeItem('vacancy');
            localStorage.removeItem('candidateVacancy');
        });
    }
    
    const handleError = ({ response: { status, data } }) => {
        if (status === 400) {
            const { validationErrors } = data.data;
            const message = validationErrors && validationErrors.contactIds ? validationErrors.contactIds[0] : 'Error desconocido';
            setOpenDialog(false);
            setOpen(false);
            Swal.fire('Error', message, 'error');
        }
    }

    const createCandidateApplies = (value) => {
        const { interviewDay, interviewHour } = value;
        return {
            id: JSON.parse(localStorage.getItem('candidateVacancy')).id,
            interviewDay: interviewDay.map(item => item.value),
            interviewHour
        };
    }

    const sendApplication = (candidateApplies) => {
        setOpenDialog(false)
        setOpen(true)
        const vacancy = JSON.parse(localStorage.getItem('vacancy'));
        axios.post(`${url}vacancies/${vacancy.id}/apply/`, [candidateApplies], Config())
            .then(handleSuccess)
            .catch(handleError);
    }

    const onPostulate = (value) => {
        const candidateApplies = createCandidateApplies(value)
        sendApplication(candidateApplies)
    }
    

    const createPaginate = (data) => {
        let result = paginateArray(data)
        setPage(1)
        setVacancies(result[0])
        setAllVacancies(result)
        setCountPage(result.length)
        setOpen(false)
    }

    const onSearch = () => {
        setOpen(true)
        let data = allVacancy.filter(vacancy => vacancy.name.toUpperCase().includes(search.toUpperCase()))
        createPaginate(data)
    }

    const onResetSearch = () => {
        setOpen(true)
        reset({
            search: ""
        })
        createPaginate(allVacancy)
    }

    const handleChange = (event, value) => {
        setOpen(true)
        setVacancies(allVacancies[value - 1])
        setPage(value);
        setOpen(false)
    };

    const paginateArray = (array) => {
        return array.reduce((resultArray, item, index) => {
            const chunkIndex = Math.floor(index / perChunk)
            if (!resultArray[chunkIndex]) {
                resultArray[chunkIndex] = []
            }
            resultArray[chunkIndex].push(item)
            return resultArray
        }, [])
    }

    const onDownload = () => {
        if (!isButtonLoading) {
            setIsButtonLoading(true)
            axios.get(`${url}vacancies/download`, ConfigHeaderBlobResponse())
                .then(({data, headers}) => {
                    const link = document.createElement("a");
                    link.target = "_blank";
                    link.download = 'vacantes.xlsx';
                    link.href = window.URL.createObjectURL(
                        new Blob([data], {
                            type: headers["content-type"],
                        })
                    );
                    link.click();
                    setIsButtonLoading(false)
                })
        }
    }

    useEffect(() => {
        setOpen(true)
        axios.get(`${url}vacancies/all`, Config()).then(({data}) => {
            setallVacancy(data)
            createPaginate(data)
            setControlledModalVacancy(true)
        }).catch(error => setOpen(false))
    }, [])

    useEffect(() => {
        const vacancyData = localStorage.getItem('vacancy');
        const candidateVacancyData = localStorage.getItem('candidateVacancy');

        if (controlledModalVacancy && vacancyData && candidateVacancyData) {
           setOpenDialog(true);
        }
    }, [controlledModalVacancy]);


    return (
        <>
        <DashboardLayout>
            <Loading open={open}/>
            <DashboardNavbar/>
            <MDBox py={3}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <Box display="flex" justifyContent="start" bottom="0px">
                            <SearchInputComponent control={control} onSearch={onSearch} onResetSearch={onResetSearch}/>
                        </Box>
                    </Grid>
                    <Grid item md={2} lg={3} xl={3}/>
                    <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                        <Box display="flex" justifyContent="end" bottom="0px">
                            <LoadingButton
                                style={{backgroundColor: "black", color: "white"}}
                                onClick={onDownload}
                                loading={isButtonLoading}
                                loadingIndicator="Loading..."
                                variant="contained"
                                fullWidth
                            >
                                {isButtonLoading ? "Descargando..." : "Exportar vacantes a excel"}
                            </LoadingButton>
                        </Box>
                    </Grid>
                    {allVacancies.length > 0 ? (
                        <>
                            <Grid item xs={12}>
                                {vancancies.map((vacancy, index) => (
                                    <CardJobsComponent key={index} vacancy={vacancy}/>
                                ))}
                            </Grid>
                            <Grid item xs={12}>
                                <Box display="flex" justifyContent="center" bottom="0px">
                                    <PaginationComponent page={page} count={countPage} onChangePage={handleChange}/>
                                </Box>
                            </Grid>
                        </>
                    ) : (<>
                        <Grid item xs={12}>
                            <Box height="600px" display="flex" justifyContent="center" alignItems="center">
                                <p>No hay vacantes disponibles</p>
                            </Box>
                        </Grid>
                    </>)}

                </Grid>
            </MDBox>
        </DashboardLayout>
        <DialogPostulateCandidateComponent 
                    handleClose={handleClose} 
                    open={openDialog} 
                    onPostulate={onPostulate}/>
        </>
    );
}

export default Dashboard;
