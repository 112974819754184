const Config = () => {
    const token = localStorage.getItem("token");
    if (token !== "" && token !== null) {
        return {
            headers: {
                Authorization: `Bearer ${token.replaceAll("\"","")}`,
            }
        }
    } else {
        return {
            headers: {}
        }
    }

}

const ConfigHeaderMultiPart = () => {
    const token = localStorage.getItem("token")
    if (token !== "") {
        return {
            headers: {
                Authorization: `Bearer ${token.replaceAll("\"","")}`,
                'Content-Type': 'multipart/form-data'
            }
        }
    }else {
        return {
            headers: {}
        }
    }
}

const ConfigHeaderBlobResponse = () => {
    const token = localStorage.getItem("token")
    if (token !== "") {
        return {
            headers: {
                Authorization: `Bearer ${token.replaceAll("\"","")}`,
            },
            responseType: "blob"
        }
    }else {
        return {
            headers: {}
        }
    }
}

const BeareToken = () => {
    const token = localStorage.getItem("token")
    if (token !== "") {
        return `Bearer ${token.replaceAll("\"","")}`
    }else {
        return ''
    }
}

export {Config, ConfigHeaderMultiPart, ConfigHeaderBlobResponse, BeareToken}
