export const Translation = {
    body: {
        noMatch: "Lo sentimos, No se encontraron registros coincidentes",
        toolTip: "Ordenar",
        columnHeaderTooltip: column => `Ordenar por ${column.label}`
    },
    pagination: {
        next: "Página Siguiente ",
        previous: "Página Anterior",
        rowsPerPage: "Filas por pagina:",
        displayRows: "De",
    },
    toolbar: {
        search: "Buscar",
        downloadCsv: "Descargar CSV",
        print: "Imprimir",
        viewColumns: "Ver Columnas",
        filterTable: "Filtrar Tablas",
    },
    filter: {
        all: "Todo",
        title: "Filtros",
        reset: "Limpiar",
    },
    viewColumns: {
        title: "Mostrar Columnas",
        titleAria: "Mostrar/Ocultar Tabla de Columnas",
    },
    selectedRows: {
        text: "Fila(s) Seleccionadas",
        delete: "Eliminar",
        deleteAria: "Seleccionar Filas Para Eliminar",
    },
}
