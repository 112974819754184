import React, {useEffect, useState} from "react";

import Grid from "@mui/material/Grid";
import axios from "axios";
import {Button, CircularProgress, Typography} from "@mui/material";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import {Link, useNavigate} from "react-router-dom";

import MDBox from "../../components/MDBox";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import Loading from "../../examples/loading";
import url from "../../config/index"
import TimelineComponent from "./components/timelineComponent";
import {Config} from "../../utils/configHeader";
import PaginationComponent from "../../examples/Pagination";
import ListItemCountComponent from "./components/listItemCountComponent";

const countInitial = {
    contracted: 0,
    processing: 0,
    rejected: 0,
    stillToProcess: 0,
    inPortafolio: 0,
    fired: 0,
    cancelled: 0,
};

const activitiesInitial = []

function Dashboard() {
    const [dashboard, setDashboard] = useState(countInitial)
    const [activities, setActivities] = useState(activitiesInitial)
    const [page, setPage] = React.useState(1);
    const [countPage, setCountPage] = useState(1)
    const [open, setOpen] = useState(false)
    const [activeProgressBar, setActiveProgressBar] = useState(true)
    const navigate = useNavigate()

    useEffect(() => {
        axios.get(`${url}dashboard/candidates-status`, Config()).then(({data:{statusCount}}) => {
            setDashboard({
                contracted: statusCount['Contratado'],
                processing: statusCount['Procesando'],
                rejected: statusCount['Rechazado'],
                stillToProcess: statusCount['Todavía por procesar'],
                inPortafolio: statusCount['En cartera'],
                fired: statusCount['Despedido'],
                cancelled: statusCount['Cancelado'],
            })

            setOpen(false)
        })
        axios.get(`${url}dashboard/activities`, Config()).then(({data}) => {
            setActivities(data.activities)
            setPage(data.currentPage)
            setCountPage(data.totalPages)
            setOpen(false)
            setActiveProgressBar(false)
        })

    }, [])

    const handleChange = (event, value) => {
        setActiveProgressBar(true)
        axios.get(`${url}dashboard/activities?page=${value}`, Config()).then(({data}) => {
            setActivities(data.activities)
            setCountPage(data.totalPages)
            setPage(value);
            setActiveProgressBar(false)
        })
    };

    const onClickDetail = (e) => {
        localStorage.setItem("statusCount", e)
        navigate("/dashboard/detalles-de-candidatos")
    }

    useEffect(() => {
        axios.get(`${url}contacts/all`, Config()).then(({data}) => {
            localStorage.setItem("candidate", JSON.stringify(data))
        })
    }, [])

    return (
        <DashboardLayout>
            <Loading open={open}/>
            <DashboardNavbar/>
            <MDBox py={3}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12} lg={6} xl={8}>
                        <MDBox mb={1.5}>
                            <Card style={{height: "100%", minHeight: "500px"}}>
                                <Box sx={{display: 'flex', justifyContent: 'space-between', ml: 4, mr:4}}>
                                    <Typography
                                        style={{marginTop: "2%", marginBottom: "2%"}}
                                        align="center"
                                        variant="h3">
                                        Historial
                                        de actividades
                                    </Typography>
                                    <Link to="/dashboard/actividad">
                                    <Button sx={{mt: 2.5}}>ver más</Button>
                                    </Link>

                                </Box>
                                {activeProgressBar && (
                                    <>
                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            margin: "auto"
                                        }}>
                                            <CircularProgress/>
                                        </Box>
                                    </>)
                                }
                                {!activeProgressBar && (
                                    <>
                                        <TimelineComponent activities={activities}/>
                                        <Box display="flex" justifyContent="center" bottom="0px"
                                             style={{marginTop: "2%", marginBottom: "2%"}}>
                                            <PaginationComponent page={page} count={countPage}
                                                                 onChangePage={handleChange}/>
                                        </Box>
                                    </>
                                )}
                            </Card>
                        </MDBox>
                    </Grid>
                    <Grid item xs={12} md={12} lg={6} xl={4}>
                        <ListItemCountComponent dashboard={dashboard} onClickDetail={onClickDetail}/>
                    </Grid>
                </Grid>
            </MDBox>
        </DashboardLayout>
    );
}

export default Dashboard;
