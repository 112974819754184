import React, {useEffect, useState} from "react";

import axios from "axios";
import {Grid} from "@mui/material";

import url from "../../../../../config";
import DashboardLayout from "../../../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../../../examples/Navbars/DashboardNavbar";
import Loading from "../../../../../examples/loading";
import MDBox from "../../../../../components/MDBox";
import TableStandertComponent from "../../../../../examples/MaterialTables/tableStandartComponent";
import {Config} from "../../../../../utils/configHeader";

const columns = [
    {title: 'id', field: 'id', hidden: true},
    {
        title: 'Nombre',
        field: 'contactDTO.name',
    },
    {
        title: 'Apellido',
        field: 'contactDTO.lastname',
    },
    {
        title: 'Observación',
        field: 'rejectionReason'
    },
    {
        title: 'Oferta',
        field: 'vacancyDTOsList[0].name',
    },
    {
        title: 'Fecha de Postulación ',
        field: 'createdAt',
        type: 'date',
        dateSetting: {format: 'dd/MM/yyyy'},
        editable: 'never',
    },
];

const options = {
    rowStyle: {
        fontSize: 15,
    },
    headerStyle: {
        textAlign: 'center',
        fontWeight: 'bolder',
    },
    cellStyle: {
        textAlign: 'left',
    },
    actionsColumnIndex: -1,
    search: false,
    filtering: true
}

const CandidateDetailComponent = () => {
    const [open, setOpen] = useState(false);
    const [candidate, setCandidate] = useState([])
    const [typeSearch, setTypeSearch] = useState()

    const getTitle = (title) => {
        if (title === "Todavía por procesar") {
            return "Postulados"
        }
        if (title === "Procesando") {
            return "en Evaluación Técnica"
        }
        if (title === "Contratado") {
            return "Contratados"
        }
        if (title === "Rechazado"){
            return "Rechazados"
        }
        if (title === "Cancelado"){
            return "Cancelados"
        }
        if (title === "Despedido"){
            return "Despedidos"
        }
        if (title === "En cartera"){
            return "con Contratos Finalizados"
        }
        return title;
    }

    useEffect(() => {
        setOpen(true)
        let type = localStorage.getItem('statusCount')
        setTypeSearch(type)
        axios.get(`${url}job-applications/status/${type}`, Config()).then(({data}) => {
            setCandidate(data)
            setOpen(false)
        })
    }, [])

    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <Loading open={open}/>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <MDBox mb={2} style={{margin: "2%"}}>
                        <TableStandertComponent
                            title={`Detalles de Candidatos ${getTitle(typeSearch)}`}
                            columns={columns}
                            data={candidate}
                            options={options}
                            action={[]}
                        />
                    </MDBox>
                </Grid>
            </Grid>
        </DashboardLayout>
    )
}

export default CandidateDetailComponent
