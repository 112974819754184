import {DashboardCustomize, FilePresent, Hail, Logout, Work, InsertDriveFile} from "@mui/icons-material";

import {CandidateManagement, Dashboard, JobOffert, LogoutComponent, ResourceView, InvoiceManagement} from './layouts/index'

const routesSidenav = [
    {
        type: "collapse",
        name: "Dashboard",
        key: "dashboard",
        icon: <DashboardCustomize fontSize="small"/>,
        route: "/dashboard",
        auth: true,
        component: <Dashboard />,
    },
    {
        type: "collapse",
        name: "Vacantes",
        key: "vacantes",
        icon: <Work fontSize="small"/>,
        route: "/vacantes",
        component: <JobOffert />,
    },
    {
        type: "collapse",
        name: "Gestion de candidatos",
        key: "gestion-de-candidatos",
        icon: <Hail fontSize="small"/>,
        route: "/gestion-de-candidatos",
        component: <CandidateManagement />,
    
    },
    {
        type: "collapse",
        name: "Gestión De Facturas",
        key: "gestion-de-facturas",
        icon: <InsertDriveFile fontSize="small"/>,
        route: "/gestion-de-facturas",
        component: <InvoiceManagement/>,
    },
    {
        type: "collapse",
        name: "Recursos",
        key: "recursos",
        icon: <FilePresent fontSize="small"/>,
        route: "/recursos",
        component: <ResourceView/>,
    },
    {
        type: "collapse",
        name: "Cerrar sesion",
        key: "logout",
        icon: <Logout fontSize="small"/>,
        route: "/logout",
        auth: true,
        component: <LogoutComponent />,
    },

];

export default routesSidenav;
