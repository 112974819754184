import React, {useEffect, useState} from "react";

import {AddBox, Delete, Edit} from "@mui/icons-material";
import axios from "axios";
import Swal from "sweetalert2";
import {useNavigate} from "react-router-dom";
import {Chip, Grid} from "@mui/material";

import url from "../../../../config";
import DashboardLayout from "../../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../../examples/Navbars/DashboardNavbar";
import Loading from "../../../../examples/loading";
import MDBox from "../../../../components/MDBox";
import TableStandertComponent from "../../../../examples/MaterialTables/tableStandartComponent";
import {BeareToken, Config} from "../../../../utils/configHeader";
import DialogDeleteComponent from "./component/dialogDeleteComponent";

const columns = [
    {title: 'id', field: 'id', hidden: true},
    {title: 'Nombre', field: 'name',},
    {title: 'Apellido', field: 'lastname',},
    {title: 'Email', field: 'email'},
    {
        title: 'Área Desempeñada',
        field: 'professionalProfile',
        filtering: true,
        render: rowData => <div> {rowData.professionalProfile.map((profile, index) => <Chip key={index} label={profile}
                                                                                            style={{
                                                                                                backgroundColor: "black",
                                                                                                color: "white",
                                                                                                margin: 2
                                                                                            }}/>)} </div>
    },
    {title: 'País de Residencia', field: 'countryOfResidence',},
    {
        title: 'Costo del Candidato', field: 'costContact',
        type: 'currency',
        currencySetting: {currencyCode: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 2},
    },

];

const options = {
    rowStyle: {
        fontSize: 15,
    },
    headerStyle: {
        textAlign: 'center',
        fontWeight: 'bolder',
    },
    cellStyle: {
        textAlign: 'left',
    },
    actionsColumnIndex: -1,
    filtering: true,
    search: false
}

const CandidateComponent = () => {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [candidate, setCandidate] = useState([])
    const [openDialog, setOpenDialog] = useState(false)
    const [description, setDescription] = useState('')
    const [id, setId] = useState()

    const handleClickOpen = (id) => {
        setOpenDialog(true);
        setId(id)
    };

    const handleClose = () => {
        setOpenDialog(false);
    };

    const onChangeDescription = value => {
        setDescription(value.target.value)
    }

    useEffect(() => {
        setOpen(true)
        axios.get(`${url}contacts/all`, Config()).then(({data}) => {
            setCandidate(data)
            localStorage.setItem("candidate", JSON.stringify(data))
            setOpen(false)
        }).catch(error => setOpen(false))
    }, [])

    const onDelete = () => {
        handleClose()
        setOpen(true)
        axios.delete(`${url}contacts/${id}`, {
            headers: {
                Authorization: BeareToken()
            },
            data: {
                reason: description
            }
        }).then((resp) => {
            axios.get(`${url}contacts/all`, Config()).then(({data}) => {
                setDescription("")
                setCandidate(data)
                setOpen(false)
            })
        }).catch(({response: {status}}) => {
            if (status === 500) {
                setOpen(false)
                Swal.fire(
                    'Error',
                    "Ha ocurrido un error interno",
                    'error'
                )
            }
        })
    }

    const actions = [
        {
            icon: () => <AddBox/>,
            tooltip: 'Registrar Candidato',
            isFreeAction: true,
            onClick: (event, rowData) => navigate("/gestion-de-candidatos/candidatos/registro")
        },
        {
            icon: () => <Edit/>,
            tooltip: 'Editar Candidato',
            onClick: (event, rowData) => navigate("/gestion-de-candidatos/candidatos/" + rowData.id)
        },
        {
            icon: () => <Delete/>,
            tooltip: 'Eliminar Candidato',
            onClick: (event, rowData) => handleClickOpen(rowData.id)
        },
    ]

    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <Loading open={open}/>
            <DialogDeleteComponent
                open={openDialog}
                handleClose={handleClose}
                description={description}
                onChangeDescription={onChangeDescription}
                onRegister={onDelete}
            />
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <MDBox mb={2} style={{margin: "2%"}}>
                        <TableStandertComponent
                            title="Candidatos"
                            columns={columns}
                            data={candidate}
                            options={options}
                            action={actions}
                        />
                    </MDBox>
                </Grid>
            </Grid>
        </DashboardLayout>
    )
}

export default CandidateComponent
