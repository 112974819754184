import {Controller} from "react-hook-form";
import FormControl from "@mui/material/FormControl";
import {TextField} from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import {DatePicker} from "@mui/lab";

function disableWeekends(date) {
    return date.getDay() === 0 || date.getDay() === 6;
  }

const InputDatePickerController = ({control, name, label, defaultValue, views, openTo, minDate}) => {
    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            render={({field}) => (
                <FormControl fullWidth>
                    <DatePicker
                        label={label}
                        views={views}
                        minDate={ minDate === undefined ? new Date("1960-01-01") : minDate}
                        openTo={openTo}
                        {...field}
                        inputVariant="inline"
                        inputFormat="yyyy-MM-dd"
                        renderInput={(params) => <TextField fullWidth variant="outlined"
                                                            margin="normal" {...params} />}
                        />
                </FormControl>
            )}
        />
    )
}

InputDatePickerController.prototype = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    control: PropTypes.element.isRequired,
    defaultValue: PropTypes.any.isRequired

}

export default InputDatePickerController;
