import React, {useState} from "react";

import {useForm} from "react-hook-form";
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid} from "@mui/material";
import * as yup from "yup";
import {yupResolver} from '@hookform/resolvers/yup';
import {CustomAxios} from "../../../components/custom/axiosCustom";
import {Config} from "../../../utils/configHeader";
import {ChargedAutomaticComponent} from "./chargedAutomaticComponent";
import {ErrorAlert, SuccessAlert}  from "../../../components/custom/SwalCustom"


const validationFileInitial = {
    required: false,
    type: false,
    size: false
}

const schema = yup.object().shape({
    ccEmail: yup.string().email("Debe ser un correo válido"),
    invoiceDate: yup.date().required("Es Requerido"),
    invoiceNumber: yup.string().required("Es Requerido"),
    total: yup.string().required("Es Requerido"),
}).required();

export const DialogChargedInvoiceComponent = ({handleClose, open, notLoading, loading}) => {
    const {control, handleSubmit, reset, watch, formState: {errors}} = useForm({
        resolver: yupResolver(schema),
    });
    const [file, setFile] = useState()
    const [validateFile, setValidateFile] = useState(validationFileInitial)

    const resetField = () => {
        reset({
            ccEmail: "",
            invoiceDate: new Date(),
            invoiceNumber: 0,
            total:0,
            comments: "",
            itbmsTax:0,
            subtotal: 0,
        })
        setFile()
    }

    const validateSizeFile = (files) => {
        if (files.size < 3000000) {
            setValidateFile({
                required: false,
                type: false,
                size: false
            })

            return false
        }
        if (files.size > 3000000) {
            setValidateFile({
                required: false,
                type: false,
                size: true
            })

            return true
        }
    }

    const validateTypeFile = (files) => {
        if (files.type === "application/pdf" || files.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
            setValidateFile({
                required: false,
                type: false,
                size: false
            })

            return true
        }
        setValidateFile({
            required: false,
            type: true,
            size: false
        })
        return false
    }

    const requiredFile = () => {
        setValidateFile({
            type: false,
            size: false,
            required: true,
        })
    }

    const handleChangeFile = e => {
        if (e.target.files.length) {
            let isFileType = validateTypeFile(e.target.files[0])
            if (isFileType) {
                let isFileSize = validateSizeFile(e.target.files[0])
                if (!isFileSize) {
                    setFile(e.target.files[0])
                }
            }
            return null
        }
        requiredFile()
    };

    const onClose = () => {
        setFile()
        setValidateFile(validationFileInitial)
        resetField()
        handleClose()
    }

    const onRegister = (value) => {
        if (file === undefined) {
            requiredFile()
            return null;
        }

        if (!validateTypeFile(file) && validateSizeFile(file)) {
            return null;
        }

        loading()
        onClose()
        let formData = new FormData();
        formData.append("file", file);
        formData.append("email", localStorage.getItem('email'));
        formData.append("currency", "USD");
        formData.append("ccEmail", value["ccEmail"]);
        formData.append("comments", value["comments"]);
        formData.append("itbmsTax", value["itbmsTax"]);
        formData.append("subtotal", value["subtotal"]);
        formData.append("subTypes", value["subTypes"])
        formData.append("total", value["total"]);
        formData.append("invoiceNumber", value["invoiceNumber"]);
        formData.append("invoiceDate", value["invoiceDate"].toISOString().substring(0, 10))

        CustomAxios.post(`invoice-submissions/send-manual-invoice`, formData, Config()).then(() => {
            notLoading()
            SuccessAlert("Cargar de Factura", "Realizada con Exito").then()
        }).catch(({response: {data}}) => {
            notLoading()
            ErrorAlert("Error al Cargar Factura", data.message).then()
        })
    }

    return (
        <>
            <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
                <form onSubmit={handleSubmit(onRegister)}>
                    <DialogTitle>Carga de Factura Manual</DialogTitle>
                    <DialogContent>
                        <Box p={1}>
                            <Grid container spacing={3}>
                                <ChargedAutomaticComponent
                                    control={control}
                                    errors={errors}
                                    file={file}
                                    handleChangeFile={handleChangeFile}
                                    validateFile={validateFile}
                                />
                            </Grid>
                        </Box>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onClose}>Cancelar</Button>
                        <Button type="submit">Cargar</Button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
}
