import React, {useState} from "react";

import {useNavigate} from 'react-router-dom';
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import axios from "axios";
import {Typography} from "@mui/material"

import MDBox from "../../../components/MDBox";
import MDInput from "../../../components/MDInput";
import MDButton from "../../../components/MDButton";
import BasicLayout from "../components/BasicLayout";
import bgImage from "../../../assets/images/bg_district_1.jpg";
import Dominion from "../../../assets/images/logos/logo.svg"
import Loading from "../../../examples/loading";
import SnackbarComponent from "../../../components/Snackbar";
import url from "../../../config/index"


const stateInitial = {
    password: "",
    email: ""
}

const Basic = () => {
    localStorage.setItem("token", "")
    const [open, setOpen] = useState(false)
    const [user, setUser] = useState(stateInitial)
    const [openSnackbar, setOpenSnackbar] = useState(false)
    const [statusSnackbar, setStatusSnackbar] = useState("success")
    const [message, setMessage] = useState('')

    const navigate = useNavigate();

    const handleInput = event => {
        const {name, value} = event.target;
        setUser({
            ...user,
            [name]: value
        });
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnackbar(false);
    };

    const onSignIn = (e) => {
        e.preventDefault();
        setOpen(true)

        axios.post(`${url}auth/signin`, user)
            .then(function ({status, data}) {
                if (status === 200) {
                    localStorage.setItem("user", JSON.stringify(data))
                    localStorage.setItem("token", JSON.stringify(data.token))
                    localStorage.setItem("showRateProvider", JSON.stringify(data.showRateProvider))
                    localStorage.setItem("email", JSON.stringify(data.email).replaceAll("\"",""))
                    localStorage.setItem("name", JSON.stringify(data.name).replaceAll("\"",""))
                    localStorage.setItem("sendInvoice", JSON.stringify(data.sendInvoice))
                    localStorage.setItem("providerLite", JSON.stringify(data.providerLite))
                    navigate("/dashboard")
                }
            }).catch(({response: {status, data}}) => {
            if (status === 404) {
                setOpen(false)
                setStatusSnackbar("error")
                setMessage(data.message)
                setOpenSnackbar(true)
            } else if (status === 400) {
                setOpen(false)
                const errors = data.data.validationErrors
                if (errors.email) {
                    setMessage(errors.email[0])
                }
                if (errors.password) {
                    setMessage(errors.password[0])
                }
                setStatusSnackbar("error")
                setOpenSnackbar(true)
            } else if (status === 403) {
                setOpen(false)
                setStatusSnackbar("error")
                setMessage(data.message)
                setOpenSnackbar(true)
            }
        })
    }

    const onPassword = () => {
        setOpen(true)
        if (user.email !== "") {
            axios.post(`${url}auth/reset-password/${user.email}`)
                .then(function (response) {
                    setOpen(false)
                    setMessage("Se ha enviado un correo para la recuperación de la contraseña")
                    setStatusSnackbar("success")
                    setOpenSnackbar(true)
                }).catch(({response: {status, data}}) => {
                if (status === 404) {
                    setMessage(data.message)
                }
                if (status === 500) {
                    setMessage("Ha ocurrido un error interno")
                }
                setOpen(false)
                setStatusSnackbar("error")
                setOpenSnackbar(true)
            })
        } else {
            setOpen(false)
            setMessage("Debe introducir un email valido")
            setStatusSnackbar("error")
            setOpenSnackbar(true)
        }

    }

    return (
        <BasicLayout image={bgImage}>
            <SnackbarComponent open={openSnackbar} handleClose={handleClose} message={message}
                               alert={statusSnackbar}/>
            <Loading open={open}/>
            <Card>
                <MDBox
                    bgColor="black"
                    borderRadius="lg"
                    coloredShadow="grey"
                    mx={2}
                    mt={-3}
                    p={2}
                    mb={1}
                    textAlign="center"
                >
                    <Grid container spacing={2} justifyContent="center" sx={{mt: 1, mb: 2}}>
                        <img alt="image" src={Dominion} width="90%" height="50%"/>
                    </Grid>
                </MDBox>
                <MDBox>
                    <Typography variant="h3" align="center">Portal de Proveedores</Typography>
                </MDBox>
                <MDBox pt={4} pb={3} px={3}>
                    <MDBox component="form" role="form">
                        <MDBox mb={2}>
                            <MDInput type="email" name="email" value={user.email} onChange={handleInput} label="Correo"
                                     fullWidth/>
                        </MDBox>
                        <MDBox mb={2}>
                            <MDInput type="password" name="password" value={user.password} onChange={handleInput}
                                     label="Contraseña"
                                     fullWidth/>
                        </MDBox>
                        <MDBox mt={4} mb={1}>
                            <MDButton onClick={onSignIn} color="black" fullWidth>
                                Iniciar Sesión
                            </MDButton>
                        </MDBox>
                        <MDBox mt={4} mb={1}>
                            <MDButton onClick={onPassword} color="black" fullWidth>
                                Recuperar contraseña
                            </MDButton>
                        </MDBox>
                    </MDBox>
                </MDBox>
            </Card>
        </BasicLayout>
    );
}

export default Basic;
