/**
 =========================================================
 * Material Dashboard 2 React - v2.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import {forwardRef} from "react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import MenuItem from "@mui/material/MenuItem";
import Link from "@mui/material/Link";

// Material Dashboard 2 React components
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";

// custom styles for the NotificationItem
import menuItem from "../../../examples/Items/NotificationItem/styles";
import {Avatar, Card, CardContent, CardHeader, CardMedia, Grid, IconButton, Typography} from "@mui/material";

const NotificationItem = forwardRef(({icon, title, message, ...rest}, ref) => (
    <MenuItem {...rest} ref={ref} sx={(theme) => menuItem(theme)}>
        <Grid container>
            <Grid item xs={12}>
                <MDBox component={Link} display="flex" py={0.8} alignItems="center" lineHeight={1}>
                    <MDTypography variant="h5" color="secondary" lineHeight={0.9}>
                        {icon}
                    </MDTypography>
                    <Typography variant="h6" fontWeight="regular" sx={{ml: 1}}>
                        {title}
                    </Typography>
                </MDBox>
            </Grid>
            <Grid item xs={12}>
                <MDBox component={Link} py={0.5} alignItems="center" lineHeight={1}>
                    <Typography style={{marginLeft: 30}} variant="caption" color="secondary" lineHeight={0.75}>
                        {message}
                    </Typography>
                </MDBox>
            </Grid>

        </Grid>

    </MenuItem>
));

// Typechecking props for the NotificationItem
NotificationItem.propTypes = {
    icon: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired
};

export default NotificationItem;
