import React, {useState, useEffect} from "react";

import {useLocation} from "react-router-dom";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import {Email, Home, MenuOpen, Notifications, SupportAgent} from "@mui/icons-material";
import {Badge, CircularProgress, Divider, MenuItem, Tooltip, Typography} from "@mui/material";
import axios from "axios";
import Box from "@mui/material/Box";

import MDBox from "../../../components/MDBox";
import Breadcrumbs from "../../../examples/Breadcrumbs";
import {
    navbar,
    navbarContainer,
    navbarRow,
    navbarIconButton,
    navbarMobileMenu,
} from "./styles";
import {
    useMaterialUIController,
    setTransparentNavbar,
    setMiniSidenav,
} from "../../../context";
import DialogSupportComponent from "./component/dialogSupportComponent";
import url from "../../../config";
import Swal from "sweetalert2";
import {BeareToken, Config} from "../../../utils/configHeader";
import NotificationItem from "../../Items/NotificationItem";
import {contentSupport, titleSupport} from "./component/messageDialog";

function DashboardNavbar({absolute, light, isMini}) {
    const [navbarType, setNavbarType] = useState();
    const [controller, dispatch] = useMaterialUIController();
    const {miniSidenav, transparentNavbar, fixedNavbar, darkMode} = controller;
    const [openDialog, setOpenDialog] = useState(false)
    const [openMenu, setOpenMenu] = useState(false);
    const [message, setMessage] = useState("")
    const [notificationLoading, setNotificationLoading] = useState(false)
    const [notifications, setNotifications] = useState([])
    const route = useLocation().pathname.split("/").slice(1);

    useEffect(() => {
        setNotificationLoading(true)
        axios.get(`${url}notifications/new/all`, Config())
            .then(({status, data}) => {
                if (status === 200) {
                    setNotificationLoading(false)
                    setNotifications(data)
                }
            })
    }, [])

    useEffect(() => {
        // Setting the navbar type
        if (fixedNavbar) {
            setNavbarType("sticky");
        } else {
            setNavbarType("static");
        }

        // A function that sets the transparent state of the navbar.
        function handleTransparentNavbar() {
            setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
        }

        /**
         The event listener that's calling the handleTransparentNavbar function when
         scrolling the window.
         */
        window.addEventListener("scroll", handleTransparentNavbar);

        // Call the handleTransparentNavbar function to set the state with the initial value.
        handleTransparentNavbar();

        // Remove event listener on cleanup
        return () => window.removeEventListener("scroll", handleTransparentNavbar);
    }, [dispatch, fixedNavbar]);

    const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
    const onClickOpenDialog = () => setOpenDialog(true);
    const onClickCloseDialog = () => setOpenDialog(false);
    const onChangeMessage = (e) => setMessage(e.target.value)
    const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
    const handleCloseMenu = () => setOpenMenu(false);

    const onViewed = (id) => {
        setNotificationLoading(true)
        axios.patch(`${url}notifications/${id}/mark-as-viewed`,{}, {
            headers: {
                Authorization: BeareToken()
            }
        })
            .then((response) => {
                if (response.status === 200) {
                    axios.get(`${url}notifications/new/all`, Config())
                        .then(({status, data}) => {
                            if (status === 200) {
                                setNotificationLoading(false)
                                setNotifications(data)
                            }
                        }).catch(error => {
                        setNotificationLoading(false)
                        console.log(error)
                    })
                }
            })
    }

    const renderMenu = () => (
        <Menu
            anchorEl={openMenu}
            anchorReference={null}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
            }}
            open={Boolean(openMenu)}
            onClose={handleCloseMenu}
            sx={{mt: 2}}
            style={{overflow: 'thin', height: '500px'}}
        >
            <Box sx={{
                padding: 1
            }}>
                <Typography justifyContent="center" alignContent="center" variant="h4">
                    Notificaciones
                </Typography>
            </Box>
            <Divider/>
            {notificationLoading && (
                <MenuItem>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: "center",
                        margin: "auto",
                        width: 300,
                        height: 150
                    }}>
                        <CircularProgress/>
                    </Box>
                </MenuItem>
            )}
            {!notificationLoading && notifications.map((notification, index) => (
                <NotificationItem onClick={() => {
                    onViewed(notification.id)
                }} key={index} icon={<Email/>} title={notification.title} message={notification.message}/>
            ))}
            {notifications.length === 0 && (
                <MenuItem>
                    <MDBox display="flex" py={0.8} alignItems="center" lineHeight={1}>
                        <Typography variant="h6" fontWeight="regular" sx={{ml: 1}}>
                            Sin notificaciones
                        </Typography>
                    </MDBox>
                </MenuItem>
            )}
        </Menu>
    );

    const onSendMessage = () => {
        onClickCloseDialog()
        axios.post(`${url}communication`, {message: message}, Config())
            .then(({status}) => {
                if (status === 200) {
                    setMessage("")
                    Swal.fire(
                        "Mensaje Enviado",
                        "Con Exito",
                        'success'
                    ).then( )
                }
            })
    }

    // Styles for the navbar icons
    const iconsStyle = ({palette: {dark, white, text}, functions: {rgba}}) => ({
        color: () => {
            let colorValue = light || darkMode ? white.main : dark.main;

            if (transparentNavbar && !light) {
                colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
            }

            return colorValue;
        },
    });

    return (
        <AppBar
            position={absolute ? "absolute" : navbarType}
            color="inherit"
            sx={(
                theme) => navbar(theme, {transparentNavbar, absolute, light, darkMode})}
        >
            <Toolbar sx={(theme) => navbarContainer(theme)}>
                <MDBox color="inherit" mb={{xs: 1, md: 0}} sx={(theme) => navbarRow(theme, {isMini})}>
                    <Breadcrumbs icon={<Home/>} title={route[route.length - 1]} route={route} light={light}/>
                </MDBox>
                {isMini ? null : (
                    <MDBox sx={(theme) => navbarRow(theme, {isMini})}>
                        <MDBox color={light ? "white" : "inherit"}>
                            <Tooltip title="Soporte de plataforma" arrow>
                                <IconButton onClick={onClickOpenDialog} sx={navbarIconButton} size="small"
                                            disableRipple>
                                    <SupportAgent fontSize="medium" sx={iconsStyle}/>
                                </IconButton>
                            </Tooltip>
                            <IconButton
                                size="small"
                                disableRipple
                                color="inherit"
                                sx={navbarIconButton}
                                aria-controls="notification-menu"
                                aria-haspopup="true"
                                variant="contained"
                                onClick={handleOpenMenu}
                            >
                                <Badge badgeContent={notifications.length} color="primary">
                                    <Notifications fontSize="medium" sx={iconsStyle}/>
                                </Badge>
                            </IconButton>
                            {renderMenu()}

                            <DialogSupportComponent
                                open={openDialog}
                                title={titleSupport}
                                content={contentSupport}
                                onClickClose={onClickCloseDialog}
                                message={message}
                                onChangeMessage={onChangeMessage}
                                onSend={onSendMessage}
                            />
                            <IconButton
                                size="small"
                                disableRipple
                                color="inherit"
                                sx={navbarMobileMenu}
                                onClick={handleMiniSidenav}
                            >
                                {miniSidenav ? <MenuOpen sx={iconsStyle} fontSize="medium"/> :
                                    <Menu sx={iconsStyle} fontSize="medium" open={false}/>}
                            </IconButton>

                        </MDBox>
                    </MDBox>
                )}
            </Toolbar>
        </AppBar>
    );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
    absolute: false,
    light: false,
    isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
    absolute: PropTypes.bool,
    light: PropTypes.bool,
    isMini: PropTypes.bool,
};

export default DashboardNavbar;
