import React, {useEffect, useState} from "react";

import {useNavigate, useParams} from 'react-router-dom';
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import axios from "axios"
import {Typography} from "@mui/material";
import {Controller, useForm} from "react-hook-form";
import Swal from "sweetalert2";

import MDBox from "../../../components/MDBox";
import MDInput from "../../../components/MDInput";
import MDButton from "../../../components/MDButton";
import BasicLayout from "../components/BasicLayout";
import bgImage from "../../../assets/images/bg_district_1.jpg";
import Dominion from "../../../assets/images/logos/logo.svg"
import Loading from "../../../examples/loading";
import SnackbarComponent from "../../../components/Snackbar";
import url from "../../../config/index"

const ResetPassword = () => {
    localStorage.setItem("token", "")
    const [open, setOpen] = useState(false)
    const [passwordEqual, setPasswordEqual] = useState(true)
    const [openSnackbar, setOpenSnackbar] = useState(false)
    const [statusSnackbar, setStatusSnackbar] = useState("success")
    const [message, setMessage] = useState('')
    const {token, email} = useParams();
    const navigate = useNavigate();
    const {handleSubmit, control, watch, formState: {errors}} = useForm();

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    const password = watch("newPassword")
    const passwordConfirmation = watch("newPasswordConfirmation")

    useEffect(() => {
        if (password !== passwordConfirmation) {
            setPasswordEqual(false)
        }
        if (password === passwordConfirmation) {
            setPasswordEqual(true)
        }

    }, [password, passwordConfirmation])

    const onChangePassword = (e) => {
        e['token'] = token
        e['email'] = email
        if (passwordEqual) {
            setOpen(true)
            axios.post(`${url}auth/change-password`, e)
                .then(function (response) {
                    setOpen(false)
                    if (response.status === 200) {
                        Swal.fire(
                            'Cambio De Contraseña',
                            'Realizada con exito',
                            'success'
                        ).then(r => navigate("/"))
                    }
                }).catch((error) => {
                if (error.response.status === 404) {
                    setOpen(false)
                    setStatusSnackbar("error")
                    setMessage(error.response.data.message)
                    setOpenSnackbar(true)
                }
            })
        }
    }
    return (
        <BasicLayout image={bgImage}>
            <SnackbarComponent open={openSnackbar} handleClose={handleClose} message={message}
                               alert={statusSnackbar}/>
            <Loading open={open}/>
            <Card>
                <MDBox
                    bgColor="black"
                    borderRadius="lg"
                    coloredShadow="grey"
                    mx={2}
                    mt={-3}
                    p={2}
                    mb={1}
                    textAlign="center"
                >
                    <Grid container spacing={2} justifyContent="center" sx={{mt: 1, mb: 2}}>
                        <img alt="image" src={Dominion} width="90%" height="50%"/>
                    </Grid>
                </MDBox>
                <MDBox>
                    <Typography variant="h5" align="center">Cambio De Contraseña</Typography>
                </MDBox>
                <MDBox pt={4} pb={3} px={3}>
                    <form onSubmit={handleSubmit(onChangePassword)}>
                        <MDBox mb={2}>
                            <Controller
                                name="newPassword"
                                control={control}
                                rules={{
                                    required: true,
                                    maxLength: 50,
                                    pattern: {
                                        value: /^(?=\w*\d)(?=\w*[A-Z])(?=\w*[a-z])\S{8,16}$/,
                                        message: "name is invalid"
                                    }
                                }}
                                render={({field}) => <MDInput type="password" name="newPassword"
                                                              label="Nueva Contraseña"
                                                              fullWidth
                                                              {...field}/>}
                            />
                            {errors.newPassword && errors.newPassword.type === "required" &&
                            <span style={{fontSize: 12, color: "red"}}>Es requerido</span>}
                            {errors.newPassword && errors.newPassword.type === "maxLength" &&
                            <span
                                style={{fontSize: 12, color: "red"}}>Longitud no debe ser mayor a 50 caracteres</span>}
                            {!passwordEqual &&
                            <span style={{fontSize: 12, color: "red"}}>La contraseña no coincide</span>}
                            {errors.newPassword && errors.newPassword.type === "pattern" &&
                                        <span style={{fontSize: 12, color: "red"}}>La contraseña debe tener entre 8 y 16 caracteres, al menos un dígito, una minúscula y una mayúscula.</span>}

                        </MDBox>
                        <MDBox mb={2}>
                            <Controller
                                name="newPasswordConfirmation"
                                control={control}
                                rules={{
                                    required: true,
                                    maxLength: 50
                                }}
                                render={({field}) => <MDInput type="password" name="newPasswordConfirmation"
                                                              label="Repite La Nueva Contraseña" fullWidth {...field}/>}
                            />
                            {errors.newPasswordConfirmation && errors.newPasswordConfirmation.type === "required" &&
                            <span style={{fontSize: 12, color: "red"}}>Es requerido</span>}
                            {errors.newPasswordConfirmation && errors.newPasswordConfirmation.type === "maxLength" &&
                            <span
                                style={{fontSize: 12, color: "red"}}>Longitud no debe ser mayor a 50 caracteres</span>}
                        </MDBox>
                        <MDBox mt={4} mb={1}>
                            <MDButton type="submit" color="black" fullWidth>
                                Cambiar contraseña
                            </MDButton>
                        </MDBox>
                    </form>
                </MDBox>
            </Card>
        </BasicLayout>
    );
}

export default ResetPassword;
