import Swal from "sweetalert2";

export const ErrorServer = () => {
    return Swal.fire('Error',
        'Hubo Un Problema Interno',
        'error')
};

export const SuccessAlert = (title, message) => {
    return Swal.fire(title,
        message,
        'success')
}

export const InfoAlert = (title, message) => {
    return Swal.fire(title,
        message,
        'info')
}

export const ErrorAlert = (title, message) => {
    return Swal.fire(title,
        message,
        'error')
}

