import React from "react";

import Grid from "@mui/material/Grid";
import {
    AccountBalanceWallet,
    Cancel,
    CloseFullscreen,
    DirectionsRun,
    DoDisturbAlt,
    FactCheck,
    Loop
} from "@mui/icons-material";
import Box from "@mui/material/Box";

import MDBox from "../../../components/MDBox";
import ComplexStatisticsCard from "../../../examples/Cards/StatisticsCards/ComplexStatisticsCard";
import {Tooltip} from "@material-ui/core";

const ListItemCountComponent = ({dashboard, onClickDetail}) => {
    const process = "Candidatos Postulados";
    const procesing = "Candidatos en Evaluación Tecnica, Evaluación Tecnica Satisfactoria, Entrevista Programada y Aprobado por Cliente";
    const hired = "Candidato Contratado";
    const rejected = "Candidatos Rechazados";
    const portafolio = "Candidatos con Contratos Finalizados";
    const cancelled = "Candidatos Cancelados";
    const fired = "Candidatos Despedidos";

    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Tooltip title={process}>
                        <MDBox style={{cursor: 'pointer'}} mb={1.5}
                               onClick={() => onClickDetail("Todavía por procesar")}>
                            <ComplexStatisticsCard
                                icon={CloseFullscreen}
                                title="Candidatos Postulados"
                                count={dashboard.stillToProcess}
                                showCount={true}
                                percentage={{
                                    color: "success",
                                    amount: "+3%",
                                    label: "than last month",
                                }}
                            />
                        </MDBox>
                    </Tooltip>

                </Grid>
                <Grid item xs={12}>
                    <Tooltip title={procesing}>
                        <MDBox style={{cursor: 'pointer'}} mb={1.5} onClick={() => onClickDetail("Procesando")}>
                            <ComplexStatisticsCard
                                icon={Loop}
                                title="Evaluación Técnica"
                                count={dashboard.processing}
                                showCount={true}
                                percentage={{
                                    color: "success",
                                    amount: "+3%",
                                    label: "than last month",
                                }}
                            />
                        </MDBox>
                    </Tooltip>
                </Grid>
                <Grid item xs={12}>
                    <Tooltip title={hired}>
                        <MDBox style={{cursor: 'pointer'}} mb={1.5} onClick={() => onClickDetail("Contratado")}>
                            <ComplexStatisticsCard
                                icon={FactCheck}
                                title="Candidatos Contratados"
                                count={dashboard.contracted}
                                showCount={true}
                                percentage={{
                                    color: "success",
                                    amount: "+3%",
                                    label: "than last month",
                                }}
                            />
                        </MDBox>
                    </Tooltip>
                </Grid>
                <Grid item xs={12}>
                    <Tooltip title={rejected}>
                        <MDBox style={{cursor: 'pointer'}} mb={1.5} onClick={() => onClickDetail("Rechazado")}>
                            <ComplexStatisticsCard
                                icon={DoDisturbAlt}
                                title="Candidatos Rechazados"
                                count={dashboard.rejected}
                                showCount={true}
                                percentage={{
                                    color: "success",
                                    amount: "+3%",
                                    label: "than last month",
                                }}
                            />
                        </MDBox>
                    </Tooltip>
                </Grid>
                <Grid item xs={12}>
                    <Tooltip title={portafolio}>
                        <MDBox style={{cursor: 'pointer'}} mb={1.5} onClick={() => onClickDetail("En cartera")}>
                            <ComplexStatisticsCard
                                icon={AccountBalanceWallet}
                                title="Contratos Finalizados"
                                count={dashboard.inPortafolio}
                                showCount={true}
                                percentage={{
                                    color: "success",
                                    amount: "+3%",
                                    label: "than last month",
                                }}
                            />
                        </MDBox>
                    </Tooltip>
                </Grid>
                <Grid item xs={12}>
                    <Tooltip title={cancelled}>
                        <MDBox style={{cursor: 'pointer'}} mb={1.5} onClick={() => onClickDetail("Cancelado")}>
                            <ComplexStatisticsCard
                                icon={Cancel}
                                title="Candidatos Cancelados"
                                count={dashboard.cancelled}
                                showCount={true}
                                percentage={{
                                    color: "success",
                                    amount: "+3%",
                                    label: "than last month",
                                }}
                            />
                        </MDBox>
                    </Tooltip>
                </Grid>
                <Grid item xs={12}>
                    <Tooltip title={fired}>
                        <MDBox style={{cursor: 'pointer'}} mb={1.5} onClick={() => onClickDetail("Despedido")}>
                            <ComplexStatisticsCard
                                icon={DirectionsRun}
                                title="Candidatos Despedidos"
                                count={dashboard.fired}
                                showCount={true}
                                percentage={{
                                    color: "success",
                                    amount: "+3%",
                                    label: "than last month",
                                }}
                            />
                        </MDBox>
                    </Tooltip>
                </Grid>
            </Grid>
        </Box>
    );
}

export default ListItemCountComponent
