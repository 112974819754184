import React from "react";

import Card from "@mui/material/Card";
import {FilePresent, PictureAsPdf, Link} from "@mui/icons-material";
import {Box} from "@mui/material";

import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";

const CardResourseComponent = ({color, title, url, typeIcon}) => {
    const onRedirect = () => {
        window.open(url, "_blank");
    }
    const getIcon = (typeIcon) => {
        switch (typeIcon) {
            case "pdf":
                return PictureAsPdf
            case "docx":
                return FilePresent
            case "url":
                return Link
            default:
                return FilePresent
        }
    }
    const Icons = getIcon(typeIcon);
    return (
        <Card onClick={onRedirect} sx={{cursor: 'pointer'}}>
            <Box display="flex" justifyContent="space-between" pt={1} px={2}>
                <MDBox
                    variant="gradient"
                    bgColor={color}
                    color={color === "light" ? "dark" : "white"}
                    coloredShadow={color}
                    borderRadius="xl"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width="4rem"
                    height="4rem"
                    mt={-3}
                >
                    <Icons fontSize="medium" color="inherit"/>
                </MDBox>
                <Box sx={{display: 'inline-flex', flexWrap: 'wrap', height: "3.7rem"}}>
                    <MDTypography variant="h5" fontWeight="light" color="text" style={{marginLeft: 5}}>
                        {title}
                    </MDTypography>
                </Box>
            </Box>
        </Card>
    );
}

CardResourseComponent.defaultProps = {
    color: "info",
};

export default CardResourseComponent
