import React,  {useEffect, useState} from "react";

import axios from "axios";
import {Grid} from "@mui/material";

import url from "../../config";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import Loading from "../../examples/loading";
import MDBox from "../../components/MDBox";
import TableStandertComponent from "../../examples/MaterialTables/tableStandartComponent";
import {Config} from "../../utils/configHeader";

const columns = [
    {title: 'id', field: 'id', hidden: true},
    {
        title: 'Descripción',
        field: 'description',
    },
    {
        title: 'Fase del Candidato',
        field: 'jobApplicationStatus',
    },
    {
        title: 'Estado de Fase',
        field: 'phase',
    },
    {
        title: 'Fecha de Acción',
        field: 'createdAt',
        type: 'date',
        dateSetting: {format: 'dd/MM/yyyy'},
        editable: 'never',

    },
    {title: 'Origen', field: 'origin',},
];

const options = {
    rowStyle: {
        fontSize: 15,
    },
    headerStyle: {
        textAlign: 'center',
        fontWeight: 'bolder',
    },
    cellStyle: {
        textAlign: 'left',
    },
    actionsColumnIndex: -1,
    search: false,
    filtering: true
}

const HistoryActivityView = () => {
    const [open, setOpen] = useState(false);
    const [historyActivity, setHistoryActivity] = useState([])

    useEffect(() => {
        setOpen(true)
        axios.get(`${url}dashboard/activities/all`, Config()).then(({data}) => {
            setHistoryActivity(data)
            setOpen(false)
        })
    }, [])

    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <Loading open={open}/>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <MDBox mb={2} style={{margin: "2%"}}>
                        <TableStandertComponent
                            title="Historial de actividades"
                            columns={columns}
                            data={historyActivity}
                            options={options}
                            action={[]}
                        />
                    </MDBox>
                </Grid>
            </Grid>
        </DashboardLayout>
    )
}

export default HistoryActivityView
