import { Tooltip } from "@material-ui/core";
import { IconButton, Button } from "@mui/material";
import {Add, Check, Refresh} from "@mui/icons-material";
import React from "react";

export const CustomToolbarComponent = ({ title, onClickAction, tooltip }) => {
  return (
    <>
      <Tooltip title={tooltip}>
          <IconButton onClick={onClickAction} >
              <Refresh/>
          </IconButton>
      </Tooltip>
    </>
  );
};
