import {
    DashboardCustomize,
    Login,
    Logout,
    RequestQuote, Settings, FilePresent
} from "@mui/icons-material";
import {
    CandidateComponent, CandidateDetailComponent,
    CandidateManagement, CandidateRegisterComponent, CandidateSendComponent,
    Dashboard,
    HistoryActivityView, JobDetailComponent,
    JobOffert,
    LogoutComponent, NotPermission, ResetPassword,
    ResourceView,
    SignIn,
    InvoiceManagement,
    VisualizerInvoicesComponent
} from './layouts'
import PrivateRoute from "./PrivateRoute";

const routes = [
    {
        type: "collapse",
        name: "Dashboard",
        key: "dashboard",
        icon: <DashboardCustomize fontSize="small"/>,
        route: "/dashboard",
        auth: true,
        roles: ['ADMINISTRATOR', 'MANAGER', 'FINANCIAL_CONTROL'],
        component: <PrivateRoute><Dashboard/></PrivateRoute>,
    },
    {
        type: "collapse",
        name: "activity",
        key: "activity",
        icon: <DashboardCustomize fontSize="small"/>,
        route: "/dashboard/actividad",
        auth: true,
        roles: ['ADMINISTRATOR', 'MANAGER', 'FINANCIAL_CONTROL'],
        component: <PrivateRoute><HistoryActivityView/></PrivateRoute>,
    },
    {
        type: "collapse",
        name: "Vacantes",
        key: "job-offers",
        icon: <Settings fontSize="small"/>,
        route: "/vacantes",
        component: <PrivateRoute><JobOffert/></PrivateRoute>,
    },
    {
        type: "collapse",
        name: "Ofertas De Empleos",
        key: "view-job-offer",
        icon: <Settings fontSize="small"/>,
        route: "/vacantes/:id",
        component: <PrivateRoute><JobDetailComponent/></PrivateRoute>,
    },
    {
        type: "collapse",
        name: "Gestión De Candidatos",
        key: "candidate-management",
        icon: <Settings fontSize="small"/>,
        route: "/gestion-de-candidatos",
        component: <PrivateRoute><CandidateManagement/></PrivateRoute>,
    },
    {
        type: "collapse",
        name: "Candidatos",
        key: "list-candidates",
        icon: <Settings fontSize="small"/>,
        route: "/gestion-de-candidatos/candidatos",
        component: <PrivateRoute><CandidateComponent/></PrivateRoute>,
    },
    {
        type: "collapse",
        name: "Candidatos",
        key: "view-candidate",
        icon: <Settings fontSize="small"/>,
        route: "/gestion-de-candidatos/candidatos/:id",
        component: <PrivateRoute><CandidateRegisterComponent/></PrivateRoute>,
    },
    {
        type: "collapse",
        name: "Candidatos",
        key: "detail-candidate",
        icon: <Settings fontSize="small"/>,
        route: "/dashboard/detalles-de-candidatos",
        component: <PrivateRoute><CandidateDetailComponent/></PrivateRoute>,
    },
    {
        type: "collapse",
        name: "Candidatos",
        key: "new-candidate",
        icon: <Settings fontSize="small"/>,
        route: "/gestion-de-candidatos/candidatos/registro",
        component: <PrivateRoute><CandidateRegisterComponent/></PrivateRoute>,
    },
    {
        type: "collapse",
        name: "Gestión De Candidatos",
        key: "sent-candidates",
        icon: <Settings fontSize="small"/>,
        route: "/gestion-de-candidatos/candidatos-enviados",
        component: <PrivateRoute><CandidateSendComponent/></PrivateRoute>,
    },
    {
        type: "collapse",
        name: "Gestión De Facturas",
        key: "invoices",
        icon: <Settings fontSize="small"/>,
        route: "/gestion-de-facturas",
        component: <PrivateRoute><InvoiceManagement/></PrivateRoute>,
    },
    {
        type: "collapse",
        name: "Gestión De Facturas",
        key: "invoices",
        icon: <Settings fontSize="small"/>,
        route: "/gestion-de-facturas/facturas-enviadas",
        component: <PrivateRoute><VisualizerInvoicesComponent/></PrivateRoute>,
    },
    
    {
        type: "collapse",
        name: "Sign In",
        key: "sign-in",
        icon: <Login fontSize="small"/>,
        route: "/",
        auth: false,
        roles: ['PUBLIC'],
        component: <SignIn/>,
    },
    {
        type: "collapse",
        name: "Reset password",
        key: "reset-password",
        icon: <Login fontSize="small"/>,
        route: "/reset-password/:token/:email",
        auth: false,
        roles: ['PUBLIC'],
        component: <ResetPassword/>,
    },
    {
        type: "collapse",
        name: "Cerrar Sesión",
        key: "Cerrar Sesión",
        icon: <Logout fontSize="small"/>,
        route: "/logout",
        auth: false,
        roles: ['PUBLIC'],
        component: <LogoutComponent/>,
    },
    {
        type: "collapse",
        name: "Recursos",
        key: "recursos",
        icon: <FilePresent fontSize="small"/>,
        route: "/recursos",
        component: <ResourceView />,
    },
    {
        type: "collapse",
        name: "Sin Permisos",
        key: "Sin Permisos",
        icon: <RequestQuote fontSize="small"/>,
        route: "/not-permission",
        auth: true,
        component: <NotPermission/>,
        roles: ['PUBLIC'],
    },

];

export default routes;
