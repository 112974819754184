import React, {useEffect, useState} from 'react';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import {useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";

import Loading from "../../../examples/loading";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import MDBox from "../../../components/MDBox";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DialogCandidateComponent from "./dialogCandidateComponent";
import url from "../../../config";
import {Config} from "../../../utils/configHeader";
import {dollarUS} from "../../../utils/moneyFormat";

export default function JobDetailComponent() {
    const [open, setOpen] = useState(false)
    const [openDialog, setOpenDialog] = useState(false);
    const [vacancy, setVacancy] = useState()
    const [candidates, setCandidates] = useState([]);
    const [candidatesApplies, setCandidatesApplies] = useState([])

    const {id} = useParams()
    const navigate = useNavigate();

    useEffect(() => {
        setOpen(true)
        axios.get(`${url}vacancies/${id}`, Config()).then(({data}) => {
            setVacancy(data)
            setOpen(false)
        })
    }, [])

    useEffect(() => {
        axios.get(`${url}contacts/available-for-vacancy/${id}`, Config()).then(({data}) => {
            localStorage.setItem("candidate", JSON.stringify(data))
            if (data.length === 0){
                Swal.fire(
                    "Información",
                    "Todos los candidatos han sido postulados a esta vacante",
                    'info'
                ).then()
            }
        })
    }, [])

    const onChangeCandidate = (value) => {
        const newCandidates = value.map(candidate => {
            return {
                id: candidate.id,
                interviewDay: [],
                interviewHour: null
            }
        })

        const updateCandidates = newCandidates.map((candidate) => {
            const indexOfCandidateAlready = candidatesApplies.findIndex(c => c.id === candidate.id)
            if (indexOfCandidateAlready !== -1) return candidatesApplies[indexOfCandidateAlready]

            return candidate
        })

        setCandidatesApplies(updateCandidates)
        setCandidates(value)
    };

    const onChangeDay = (candidateId, interviewDay) => {
        const updateDayCandidatesApplies = candidatesApplies.map(candidate => {
            if (candidate.id === candidateId) {
                return {
                    ...candidate,
                    interviewDay: interviewDay.map(item => item.value)
                }
            }
            return candidate
        })

        setCandidatesApplies(updateDayCandidatesApplies)
    }
    const onChangeHour = (candidateId, interviewHour) => {
        const updateHourCandidatesApplies = candidatesApplies.map(candidate => {
            if (candidate.id === candidateId) {
                return {
                    ...candidate,
                    interviewHour
                }
            }
            return candidate
        })

        setCandidatesApplies(updateHourCandidatesApplies)
    }

    const onRegister = () => {
        handleClose()
        setOpen(true)
        axios.post(`${url}vacancies/${id}/apply/`, candidatesApplies, Config()).then((response) => {
            setOpen(false)
            Swal.fire(
                "Postulación",
                "Registrada con exito",
                'success'
            ).then(r => navigate("/gestion-de-candidatos/candidatos-enviados"))
        }).catch(({response:{status, data}}) => {
            if (status === 400) {
                let message = ""
                const errors = data.data.validationErrors
                if (errors.contactIds) {
                    message = errors.contactIds[0]
                }

                setOpen(false)
                Swal.fire(
                    'Error',
                    `${message}`,
                    'error'
                )
            }
        })
    }

    const handleClickOpen = () => {
        setOpenDialog(true);
    };

    const handleClose = () => {
        setOpenDialog(false);
    };

    var showRateProvider = (localStorage.getItem("showRateProvider") == 'true') ? true : false;

    return (
        <DashboardLayout>
            <Loading open={open}/>
            <DashboardNavbar/>
            <MDBox py={3}>
                {vacancy !== undefined && (
                    <Card>
                        <CardHeader title={vacancy.name}/>
                        <CardContent>
                            <Typography variant="h5">
                                Descripción:
                            </Typography>
                            <Typography paragraph style={{fontSize: "15px"}}>
                                {vacancy.description}
                            </Typography>
                            <Typography variant="h5">
                                Responsabilidades:
                            </Typography>
                            <Typography paragraph style={{fontSize: "15px"}}>
                                {vacancy.responsibilities}
                            </Typography>
                            <Typography variant="h5">
                                Resumen:
                            </Typography>
                            <Typography paragraph style={{fontSize: "15px"}}>
                                {vacancy.resumen}
                            </Typography>
                            <Typography variant="h5">
                                Tecnologías requeridas:
                            </Typography>
                            <Typography component={'div'} style={{fontSize: "15px"}}>
                                <ul style={{marginLeft: 30}}>
                                    {vacancy.techStack.map((techStack, index) => (
                                        <li key={index}>{techStack}</li>
                                    ))}
                                </ul>
                            </Typography>
                            
                            <Typography variant="h5">
                                Idioma requerido:
                            </Typography>
                            <Typography paragraph style={{fontSize: "15px"}}>
                                {vacancy.language}
                            </Typography>
                            <Typography variant="h5">
                                Tiempo estimado de duración:
                            </Typography>
                            <Typography paragraph style={{fontSize: "15px"}}>
                                {vacancy.estimatedHiringTime}
                            </Typography>
                            <Typography variant="h5" style={{visibility: showRateProvider ? 'visible' : 'hidden' }}>
                                Rate máximo:
                            </Typography>
                            <Typography paragraph style={{fontSize: "15px", visibility: showRateProvider ? 'visible' : 'hidden'}}>
                                {dollarUS.format(vacancy.rateMaxUSD)}
                            </Typography>
                            <Typography variant="h5" style={{visibility: showRateProvider ? 'visible' : 'hidden' }}>
                                Rate mínimo:
                            </Typography>
                            <Typography paragraph style={{fontSize: "15px", visibility: showRateProvider ? 'visible' : 'hidden'}}>
                                {dollarUS.format(vacancy.rateMinUSD)}
                            </Typography>
                            <Typography variant="h5" style={{visibility: showRateProvider ? 'visible' : 'hidden' }}>
                                Equipo de trabajo requerido:
                            </Typography>
                            <Typography paragraph style={{fontSize: "15px"}}>
                                {vacancy.requiredEquipment}
                            </Typography>
                        </CardContent>
                        <MDBox mb={2} mr={2} ml={2} mt={2}>
                            <Grid container spacing={2} direction="row" justifyContent="flex-end" alignItems="flex-end">
                                <Grid item xs={12} md={6} lg={3}>
                                    <MDBox mb={2}>
                                        <Button
                                            onClick={handleClickOpen}
                                            style={{backgroundColor: "#479925", color: "white"}}
                                            variant="contained"
                                            fullWidth>
                                            Postular candidato
                                        </Button>
                                    </MDBox>
                                </Grid>
                            </Grid>
                        </MDBox>
                    </Card>
                )}

                <DialogCandidateComponent
                    handleClickOpen={handleClickOpen}
                    handleClose={handleClose}
                    open={openDialog}
                    onChangeCandidate={onChangeCandidate}
                    candidateSelected={candidates}
                    onRegister={onRegister}
                    onChangeDay={onChangeDay}
                    onChangeHour={onChangeHour}
                />
            </MDBox>
        </DashboardLayout>
    );
}
